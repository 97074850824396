<template>
  <div class="filtermodal">
    <p class="pbz-font title">
      {{ $t('utils.filter') }}
    </p>
    <p class="pbz-font body-sm-bold">
      {{ $t('fulfillment.inventory_status') }}
    </p>
    <div class="box">
      <div
        v-for="(item, index) in inventory_state"
        :key="index"
        class="list pbz-font button-sm-medium"
        :class="item.status"
        @click.prevent="changeCategorieState(item.value, item.status)"
      >
        {{ item.title }}
      </div>
    </div>
    <a-row class="row pbz-font" style="margin-top: 20px">
      <a-col cols="12">
        {{ $t('fulfillment.warehouse') }}
      </a-col>
      <!-- $store.state.user.restriction_base?.toLowerCase() !== 'warehouse' -->
      <a-col cols="12" style="margin-top: 20px">
        <a-select
          v-model="warehouse_id"
          :max-tag-count="3"
          mode="multiple"
          size="large"
          class="w-100 select-antd-default"
          show-search
          :placeholder="$t('utils.choose_a_warehouse')"
          :options="warehouse_list"
          option-filter-prop="children"
          :filter-option="filterOption"
          @select="onSelectWarehouse"
          @deselect="onDeselectWarehouse"
        />
      </a-col>
      <a-col cols="12" style="margin-top: 20px">
        {{ $t('fulfillment.channel') }}
      </a-col>
      <a-col cols="12" style="margin-top: 20px">
        <a-select
          v-model="channel_id"
          size="large"
          class="w-100 select-antd-default"
          show-search
          :placeholder="$t('utils.choose_a_channel')"
          :options="channel_list"
          option-filter-prop="children"
          :filter-option="filterOption"
        />
      </a-col>
      <a-col cols="12" style="margin-top: 20px">
        {{ $t('utils.order_date') }}
      </a-col>
      <a-col cols="12" style="margin: 20px 0 20px 0">
        <a-range-picker
          v-model="date"
          :disabled-date="disabledDate"
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['Tanggal Awal', 'Tanggal Akhir']"
          size="large"
          style="display: contents"
          @calendarChange="calendarChange"
          @openChange="openChange"
        >
          <a-icon slot="suffixIcon" type="calendar" style="color: #0559cb" />
        </a-range-picker>
      </a-col>
      <a-col cols="5">
        <a-button
          class="cancel-button pbz-font"
          @click="resetFilter"
        >
          {{ $t('utils.clearFilter') }}
        </a-button>
      </a-col>
      <a-col cols="7" class="d-flex justify-content-end">
        <a-button
          class="cancel-button pbz-font mr-2"
          @click="cancelModal"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          class="accept-button pbz-font"
          @click.prevent="submitFilter"
        >
          {{ $t('utils.apply') }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import SearchIcon from '@/components/Icons/Search.vue'

export default {
  name: 'FilterList',
  components: {
    SearchIcon,
  },
  props: ['clear', 'warehouseList'],
  data: function () {
    return {
      inventory_state: [
        {
          value: 'SUCCESS_ALLOCATED',
          title: 'Allocated',
          status: '',
        },
        {
          value: 'NOT_YET_ALLOCATED',
          title: 'Unallocated',
          status: '',
        },
        {
          value: 'ALLOCATION_FAILED',
          title: 'OOS / Unconfirm Product',
          status: '',
        },
      ],
      date: [],
      warehouse_id: [],
      booking_state: [],
      channel_id: undefined,
      start_date: null,
      end_date: null,
    }
  },
  computed: {
    activeStatus: function () {
      return this.booking_state
    },
    channel_list() {
      return this.$store.state.business.listChannels.map(channel => ({
        value: channel.id.toString(),
        label: channel.title,
      }))
    },
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    warehouse_list() {
      if (this.warehouseList?.length > 0) {
        const listWhs = []
        this.warehouseList.forEach(item => {
          listWhs.push({
            value: item?.id || item?.warehouse_id,
            label: item.name,
          })
        })
        listWhs.unshift(
          {
            "label": "Semua Gudang",
            "value": "ALL",
          },
        )
        return listWhs
      }
      return []
    },
  },
  watch: {
    '$route.query'() {
      this.fetchDate()
      this.fetchWarehouseId()
      this.fetchChannelId()
      this.fetchBookingState()
    },
    clear: {
      deep: true,
      immediate: true,
      handler: function () {
        this.warehouse_id = []
      },
    },
  },
  mounted () {
    this.fetchDate()
    this.fetchChannelId()
    this.fetchWarehouseId()
    this.fetchBookingState()
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0]?.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    openChange(open) {
      if (open) {
        this.start_date = null
      }
    },
    calendarChange(value) {
      this.start_date = value[0]
      this.end_date = value[1]
    },
    disabledDate(current) {
      const tooLate = current && current > this.$moment().endOf('day');
      return tooLate
    },
    removeTag(tag) {
      this.warehouse_id = this.warehouse_id.filter(item => item !== tag.warehouse_id)
    },
    submitFilter() {
      this.$emit('setLoading', true)
      this.start_date = null
      this.$emit('visible', false)
      let selectedWarehouse = this.warehouse_id.filter((obj) => obj !== 'ALL').join(',')
      // if (this.warehouse_id.includes('ALL')) {
      //   selectedWarehouse = undefined
      // } else if (this.warehouse_id?.length > 0) {
      //   selectedWarehouse = this.warehouse_id.filter((obj) => obj !== 'ALL').join(',')
      // }
      this.$emit('setWarehouseId', selectedWarehouse)
      this.$router.push({
        query: {
          ...this.$route.query,
          booking_state: this.booking_state.length !== 0 ? this.booking_state.join(',') : undefined,
          channel_id: this.channel_id ? this.channel_id : undefined,
          start_date: this.date[0] ? this.$moment(this.date[0]).format() : undefined,
          end_date: this.date[1] ? this.$moment(this.date[1]).format() : undefined,
          warehouse_id: selectedWarehouse,
          page: 1,
        },
      }).catch(()=>{})
    },
    resetFilter() {
      this.$router.push({
        query: {
          ...this.$route.query,
          booking_state: undefined,
          warehouse_id: undefined,
          start_date: undefined,
          end_date: undefined,
        },
      })
      this.booking_state = []
      this.warehouse_id = []
      this.date = []
      this.start_date = null
      this.end_date =  null
      this.$emit('visible', false)
    },
    changeCategorieState: function (value, status) {
      if (status === 'active') {
        this.inventory_state.find(item => item.value === value).status = ''
        this.booking_state.pop(item => item.value === value)
      } else {
        this.inventory_state.find(item => item.value === value).status = 'active'
        this.booking_state = [...this.booking_state, value]
      }
    },
    cancelModal () {
      this.$emit('visible', false)
    },
    fetchDate() {
      this.date = this.$route.query.start_date || this.$route.query.end_date ? [this.$moment(this.$route.query.start_date), this.$moment(this.$route.query.end_date)] : []
    },
    fetchWarehouseId() {
      this.warehouse_id = this.$route.query.warehouse_id ? this.$route.query.warehouse_id.split(',') : []
    },
    fetchChannelId() {
      this.channel_id = this.$route.query.channel_id ? this.$route.query.channel_id : undefined
    },
    fetchBookingState() {
      if (this.$route.query.booking_state) {
        this.inventory_state.map(item => { item.status = '' })
        this.booking_state = this.$route.query.booking_state.split(',')
        this.inventory_state.filter(item => this.booking_state.find(param => param === item.value)).map(status => { status.status = 'active' })
      } else {
        this.inventory_state.map(item => { item.status = '' })
        this.booking_state = []
      }
    },
    onSelectWarehouse(record) {
      if (record === 'ALL') {
        this.warehouse_id = this.warehouse_list.map((obj) => obj.value)
      }
    },
    async onDeselectWarehouse(record) {
      if (record === 'ALL') {
        this.warehouse_id = await []
      } else if (record !== 'ALL' && this.warehouse_id.includes('ALL')) {
        const deselectAll = await this.warehouse_id
        this.warehouse_id = deselectAll.filter((obj) => !['ALL', record].includes(obj))
      }
    },
  },
}
</script>

<style lang="scss">
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;

  .ant-select-selection__placeholder {
    font-size: inherit;
    font-weight: normal;
    color: #595c97;
  }
}
.search-box {
  width: 100%;
  border: none;
}
.field {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  text-align: left;
  padding-left: 2.5rem !important;
  padding: 10px 15px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 5px;
  color: #999999;
}
.search-box > .i {
  position: absolute;
  margin: 1rem 0 0 0.5rem;
}
.field::placeholder {
  color: #999999 !important;
}
.filtermodal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  box-sizing: border-box;
  border-radius: 8px;

  .title {
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
    color: #1a1a1a;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  }
  .status {
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #1a1a1a;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
  }
  .box {
    display: flex;
    cursor: pointer;

    .list {
      margin-right: 10px;
      color: #999999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: auto;
      height: 31px;
      background: #ffffff;
      border: 1px solid #999999;
      box-sizing: border-box;
      border-radius: 33px;
    }

    .active {
      background: #E00000;
      color: #ffffff;
      border: 1px solid #E00000;
    }

    .list:hover:not(.active) {
      border: 1px solid #E00000;
      color: #E00000;
    }
  }
  .separator {
    position: static;
    width: 1036px;
    height: 0px;
    left: 80px;
    top: 165px;
    border: 1px solid #cccccc;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 0px;
  }
  .row {
    width: 100%;
    text-align: left;
  }
  .tag {
    margin-right: 10px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    width: auto;
    height: 31px;
    background: #E00000;
    border: 1px solid #E00000;
    box-sizing: border-box;
    border-radius: 33px;
  }
  .datetime {
    display: flex;
    padding: 10px 15px;
    position: absolute;
    height: 48px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .accept-button {
    padding: 10px 15px;
    height: 100%;
    width: auto;
    background: #E00000;
    border-radius: 5px;
    color: #ffffff;
  }
  .cancel-button {
    padding: 10px 15px;
    line-height: 21px;
    color: #E00000;
    border: 1px solid #E00000;
    width: auto;
    height: 100%;
  }
}
</style>
