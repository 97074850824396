<template>
  <div class="content-modal-notif-to-filter">
    <a-row>
      <a-col :span="24" class="d-flex justify-content-center align-items-center">
        <div class="pbz-font subtitle-md-medium"> {{ $t('utils.filter') }} </div>
      </a-col>

      <a-col :span="24" class="mt-3 d-flex justify-content-center align-items-center">
        <div
          class="pbz-font body-md-regular" style="color: #999999"
          v-html="$t('fulfillment.captionNotifFilterBeforeExport').replace(/(?:\r\n|\r|\n)/g, '<br />')"
        >
        </div>
      </a-col>

      <a-col :span="24" class="mt-5 d-flex justify-content-center align-items-center">
        <div class="button-filter" @click.prevent="() => $emit('clickSetFilter')">
          {{ $t('utils.filter') }}
        </div>
      </a-col>

      <a-col :span="24" class="mt-2 d-flex justify-content-center align-items-center">
        <div class="button-cancel" @click.prevent="() => $emit('clickCancelModal')">
          {{ $t('utils.cancel') }}
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.content-modal-notif-to-filter {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  .button-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;

    position: static;
    width: 75%;
    height: 48px;
    background: #0559CB;
    border-radius: 5px;

    cursor: pointer;
    color: #FFFFFF;
  }

  .button-cancel {
    color: #0559CB;
    cursor: pointer;
  }
}
</style>
