<template>
  <a-modal :visible="visible" :closable="false">
    <h6 class="mt-2 text-center">
      Bulk Print {{ actionType === 'print-picking-list' ? 'Picking List' : 'Shipping Label' }}
    </h6>
    <a-tag v-for="fulfillment in filterCheckedOrder" :key="fulfillment.order_number" class="my-1" color="blue">
      {{ fulfillment.order_number }}
    </a-tag>
    <a-divider class="my-2" />
    <div>Total: {{ filterCheckedOrder.length }} order</div>
    <template #footer>
      <a-button key="back" :loading="loading" class="mr-2" @click="closeModal()">
        Batalkan
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="submitModal()">
        Print
      </a-button>
    </template>
  </a-modal>
</template>

<script>

export default {
  name: 'ModalBulkPrintShippingLabel',
  props: {
    visible: Boolean,
    back: Function,
    loading: Boolean,
    listFulfillment: Array,
    actionType: String,
  },
  computed: {
    filterCheckedOrder() {
      return this.listFulfillment.filter(fulfillment => fulfillment.is_checked)
    },
  },
  methods: {
    closeModal() {
      this.$emit('handleShowModal', false)
    },
    submitModal() {
      let fulfillments = []
      this.listFulfillment.forEach(fulfillment => {
        if(fulfillment.is_checked) fulfillments.push(fulfillment.fulfillment_id)
      })
      this.$emit('handleSubmitModal', fulfillments.join(','))
      this.closeModal()
    },
  },
}
</script>

<style lang="scss" module>
</style>